import {httpsCallable} from "firebase/functions";
import {functions, storage} from "../firebase";
import {FirebaseError} from "firebase/app";
import {LatLng} from "../model/LatLng";
import {PolygonAddressResponse} from "../model/response/PolygonAddressResponse";
import {WorkResponse} from "../model/response/WorkResponse";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import {ProjectResponse} from "../model/response/ProjectResponse";
import {MemberResponse} from "../model/response/MemberResponse";
import {ref, getDownloadURL} from "firebase/storage";
import {getProjectData, getProjectWorkDatas, getWorkRecord} from "./ProjectRepository";
import {getMemberData} from "../util/project";
import moment from "moment";


export const getMedicine = async (page: number, name?: string): Promise<any> => {
    try {
        let request;
        if (name) {
            request = {
                page: page,
                name: name
            }
        } else {
            request = {
                page: page,
            }
        }

        const getMedicine = httpsCallable(functions, 'getMedicine');
        const result = await getMedicine(request)

        const response = result.data

        return response

    }catch (e) {
        console.error(e)
        throw Error('서버에 문제가 발생했습니다. 잠시 후 이용해주세요.')
    }
}

export const polygonToAddress = async (
    address: string,
    latLng?: LatLng,
): Promise<PolygonAddressResponse> => {
    try {
        let request;
        if (latLng) {
            request = {
                latLng: {
                    latitude: latLng.latitude,
                    longitude: latLng.longitude
                },
                address: address
            }
        } else {
            request = {
                latLng: null,
                address: address
            }
        }

        const polygonToAddress = httpsCallable(functions, 'polygonToAddress');
        const result = await polygonToAddress(request)

        const response = result.data as PolygonAddressResponse
        if (response.success) {
            if (response.polygon.length === 0) {
                throw Error('주소 인식에 실패했습니다. 주소를 확인해주세요.')
            }
            return response
        } else {
            throw Error(response.message)
        }
    } catch (err) {
        if (err instanceof FirebaseError) {
            console.error('code', err.code)
            if (err.code === 'functions/not-found') {
                throw Error('주소 인식에 실패했습니다. 주소를 확인해주세요.')
            } else {
                throw Error('서버에 문제가 발생했습니다. 잠시 후 이용해주세요.')
            }

        } else {
            throw Error('서버에 문제가 발생했습니다. 잠시 후 이용해주세요.')
        }
    }
}


export const downloadExcel = async (
    project: ProjectResponse,
    works: WorkResponse[],
    members: MemberResponse[]
): Promise<boolean> => {

    const excelFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const excelFileExtension = '.xlsx';
    const excelFileName = `작업기록-${Date.now()}`;
    const header = ['성명', '전화번호', '주소', '요청사항', '면적 (평)', '작업자', '작업기종', '상태', '이미지', '작업시간', '재작업요청횟수'];
    if(project.medicines) {
        for (const medicine of project.medicines) {
            header.push(medicine.name)
        }
    }

    const ws = XLSX.utils.aoa_to_sheet([
        header,
        [],
    ]);

    const totalArea = project.workStatus.complete.area + project.workStatus.progress.area + project.workStatus.expected.area +
        project.workStatus.stop.area + project.workStatus.except.area + project.workStatus.rework.area

    for (const work of works) {
        const workRecordResult = await getWorkRecord(work.workId);
        const reworkCount = workRecordResult.filter(w => w.workStatus === "재작업요청").length;
        let fileUrls = []
        for (const uploadFile of work.uploadFiles) {
            try{
            const storageRef = ref(storage, `work/${uploadFile}`)
            const url = await getDownloadURL(storageRef)
            fileUrls.push(url)
            }catch (err){
            }
        }

        const content = [
            work.farmerName,
            work.farmerPhone,
            work.address,
            work.request,
            work.area,
            work.worker,
            work.workType,
            work.workStatus,
            fileUrls.toString(),
            work.updated ? moment.unix(work.updated.seconds).format('YYYY-MM-DD HH:mm') : '',
            reworkCount
        ];

        if(project.medicines) {
            for (const medicine of project.medicines) {
                const areaPrice = medicine.amount / totalArea
                content.push(`${Math.abs(Number((areaPrice * work.area).toFixed(2)))}`);
            }
        }

        XLSX.utils.sheet_add_aoa(
            ws,
            [
                content
            ],
            {origin: -1}
        )
        ws['!cols'] = [
            {wpx: 70},
            {wpx: 100},
            {wpx: 200},
            {wpx: 100},
            {wpx: 50},
            {wpx: 50},
            {wpx: 100},
        ]
    }


    const totalMedicine = ['', '', '' , '', '', '', '', '', '', '', ''];
    if(project.medicines) {
        for (const medicine of project.medicines) {
            totalMedicine.push(Number(medicine.amount).toLocaleString());
        }
    }

    XLSX.utils.sheet_add_aoa(
        ws, [totalMedicine], {origin: -1}
    )

    const totalCnt = project.workStatus.complete.cnt + project.workStatus.progress.cnt + project.workStatus.expected.cnt +
        project.workStatus.stop.cnt + project.workStatus.except.cnt + project.workStatus.rework.cnt
    XLSX.utils.sheet_add_aoa(
        ws,
        [
            [], [], [], [], [],
            ['작업 현황', '면적 (평)', '개수'],
            ['완료', Math.abs(Number(project.workStatus.complete.area.toFixed(2))), project.workStatus.complete.cnt],
            ['진행', Math.abs(Number(project.workStatus.progress.area.toFixed(2))), project.workStatus.progress.cnt],
            ['예정', Math.abs(Number(project.workStatus.expected.area.toFixed(2))), project.workStatus.expected.cnt],
            ['중단', Math.abs(Number(project.workStatus.stop.area.toFixed(2))), project.workStatus.stop.cnt],
            ['제외', Math.abs(Number(project.workStatus.except.area.toFixed(2))), project.workStatus.except.cnt],
            ['재작업요청', Math.abs(Number(project.workStatus.rework.area.toFixed(2))), project.workStatus.rework.cnt],
            ['총', totalArea, totalCnt],
            [], [], [], [], [],
            ['작업자별 작업', '완료', '진행', '중단', '제외', '재작업요청', '총']
        ],
        {origin: -1}
    )

    for (const member of members) {
        const complete = works.filter(work => work.workerId === member.userId && work.workStatus === '완료')
        const progress = works.filter(work => work.workerId === member.userId && work.workStatus === '진행')
        const stop = works.filter(work => work.workerId === member.userId && work.workStatus === '중단')
        const except = works.filter(work => work.workerId === member.userId && work.workStatus === '제외')
        const rework = works.filter(work => work.workerId === member.userId && work.workStatus === '재작업요청')
        const completeArea = complete.reduce((acc, cur) => acc + cur.area, 0)
        const progressArea = progress.reduce((acc, cur) => acc + cur.area, 0)
        const stopArea = stop.reduce((acc, cur) => acc + cur.area, 0)
        const exceptArea = except.reduce((acc, cur) => acc + cur.area, 0)
        const reworkArea = rework.reduce((acc, cur) => acc + cur.area, 0)

        const totalWorkArea = completeArea + progressArea + stopArea + exceptArea + reworkArea
        const totalWorkCnt = complete.length + progress.length + stop.length + except.length + rework.length

        XLSX.utils.sheet_add_aoa(
            ws,
            [
                ['작업자', `${member.name} (${member.phone})`],
                ['면적 (평)', completeArea, progressArea, stopArea, exceptArea, reworkArea, totalWorkArea],
                ['개수', complete.length, progress.length, stop.length, except.length, rework.length, totalWorkCnt]
            ],
            {origin: -1}
        )

    }

    // const memberData = Object.keys(project.members).map(key => getMemberData(key, members))
    // objectToMap(project.members).forEach((member, key, map) => {
    //     const userInfo = memberData.find(data => data.userId === key)
    //
    //     if(userInfo) {
    //         const totalWorkerArea = member.status.complete.area + member.status.progress.area +
    //             member.status.stop.area + member.status.except.area + member.status.rework.area
    //         const totalWorkerCnt = member.status.complete.cnt + member.status.progress.cnt +
    //             member.status.stop.cnt + member.status.except.cnt + member.status.rework.cnt
    //         XLSX.utils.sheet_add_aoa(
    //             ws,
    //             [
    //                 ['작업자', `${userInfo.name} (${userInfo.phone})`],
    //                 ['면적 (평)', member.status.complete.area, member.status.progress.area, member.status.stop.area,
    //                     member.status.except.area, member.status.rework.area, totalWorkerArea],
    //                 ['개수', member.status.complete.cnt, member.status.progress.cnt, member.status.stop.cnt,
    //                     member.status.except.cnt, member.status.rework.cnt, totalWorkerCnt]
    //             ],
    //             {origin: -1}
    //         )
    //     }
    // })

    const wb: any = {Sheets: {data: ws}, SheetNames: ['data']};
    const excelButter = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
    const excelFile = new Blob([excelButter], {type: excelFileType});
    FileSaver.saveAs(excelFile, excelFileName + excelFileExtension);
    return true
}


const androidTest = (data: any) => {
    // @ts-ignore
    if(window.Android){
        // @ts-ignore
        window.Android.getBase64FromBlobData(data)
    }

}

const getDeviceName = (code: string): string => {
    switch (code) {
        case '001':
            return '비행기'
        case '002':
            return '헬리콥터'
        case '003':
            return '비행선'
        case '004':
            return '활공기'
        case '005':
            return '자이로플레인'
        case '006':
            return '동력패러슈트'
        case '007':
            return '무인비행기'
        case '008':
            return '무인헬리콥터'
        case '009':
            return '무인멀티콥터'
        case '010':
            return '무인비행선'
        case '011':
            return '경량비행기'
        case '012':
            return '경량헬리콥터'
        case '013':
            return '경량자이로플레인'
        case '014':
            return '경량동력패러슈트'
        case '015':
            return '무인동력비행기'
        case '016':
            return '무인동력헬리콥터'
        case '017':
            return '무인동력멀티콥터'
        default:
            return "무인동력헬리콥터"
    }
}

export const downloadMedicineExcel = async (
    projectId: string,
    deviceCode: string,
    userId?: string
): Promise<boolean> => {
    const excelFileType = 'application/vnd.ms-excel';
    const excelFileExtension = '.xls';
    const header = ['방제기기 코드', '방제기기명', '사용일자(방제일자)', '방제장소_지번주소', '방제장소_상세주소(필지구분)',
        '방제장소_상세주소(본번_부번)', '법정동코드', 'PNU코드', '사용대상 농작물명', '작물코드', '농약품목명',
        '농약코드', '방제면적', '농약등록규격', '농약포장단위' ,'농약포장단위코드', '농약사용량', '농약사용량 단위'];

    const ws = XLSX.utils.aoa_to_sheet([
        ["(작성안내) 5번 행 예시를 지우고 5번 행부터 작성하여 주시기 바랍니다. 선택값 셀은 공란으로 입력 가능합니다.  * 법정동코드는 행정표준코드관리시스템(www.code.go.kr) 에서 제공 하고 있습니다. ('초기화면'-> '자주이용하는 코드' -> '법정동' 선택)"],
        header,
        ['방제코드값', '방제기기명', 'yyyymmdd', '주소입력', '1. 일반, 2. 산 중 선택', '상세주소 (본번 - 부번)', '법정동코드 입력', '법정동코드+상세주소(19자리)', '텍스트 입력', '작물코드 입력', '품목명(상표명)', '농진청 농약코드 입력', '숫자입력 (소수점 입력가능)(단위 : ㎡)', '숫자입력 (소수점 및 특수문자 입력가능)(단위 : %)', '숫자입력 (소수점 입력가능)', '1. ㎖, 2. g 중 선택', '숫자입력 (소수점 입력가능)', '1. 봉, 2. 병 중 선택'],
        ['필수', '필수', '필수', '필수', '필수', '필수', '필수', '선택', '필수', '선택', '필수', '선택', '필수', '필수', '필수', '필수', '필수', '필수'],
        [],
    ]);

    const project = await getProjectData(projectId);
    const works = await getProjectWorkDatas(projectId);
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');


    const excelFileName = `${project.name}-${year}${month}${day}`;


    const totalWorkArea = works.reduce((acc, cur) => acc + cur.area, 0);

    for (const work of works) {
        if(userId) {
            if(work.workerId !== userId) {
                continue;
            }
        }
        if(!work.pnu) {
            continue;
        }

        const workTime = work.updated ? moment.unix(work.updated.seconds).format('YYYYMMDD') : ''
        const koreanAddress = work.address.match(/[가-힣\s]+/);
        const koreanAddress2 = koreanAddress ? koreanAddress[0].toString() : '';
        const digit11 = work.pnu?.charAt(10);
        let bonbon = work.pnu?.slice(11, 15); // 12번째부터 15번째까지 4자리 본번
        let bubun = work.pnu?.slice(15, 19);  // 16번째부터 19번째까지 4자리 부번
        // 본번과 부번에서 앞의 0을 제거
        bonbon = bonbon?.replace(/^0+/, '');
        bubun = bubun?.replace(/^0+/, '');
        bubun = bubun ? "-" + bubun  : "" ;
        const detailAddress = bonbon + bubun;

        const lawdCd = work.pnu?.slice(0, 10);

        const contentList: any[] = [];
        const content = [deviceCode, getDeviceName(deviceCode), workTime, koreanAddress2, digit11 === '1' ? '일반' : '산',
            detailAddress, lawdCd, work.pnu, work.cropName, work.cropCode
        ]

        if(project.newMedicines) {
            for (const medicine of project.newMedicines) {
                const perArea = (medicine.amount * 1000) / totalWorkArea;
                const amount = perArea * work.area;
                const tempContent = [...content];
                tempContent.push(medicine.name)
                tempContent.push(medicine.code)
                tempContent.push(work.area.toLocaleString('ko-KR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))
                tempContent.push(medicine.reg_cpnt || '')
                //포장단위
                // tempContent.push(medicine.bottle.toFixed(2));
                tempContent.push(medicine.bottle.toLocaleString('ko-KR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
                tempContent.push('㎖');
                //사용량
                // tempContent.push(amount.toFixed(2));
                tempContent.push(amount.toLocaleString('ko-KR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
                // tempContent.push(`${(amount/medicine.bottle).toFixed(2)}병`);
                tempContent.push('병');
                contentList.push(tempContent);
            }
        }



        XLSX.utils.sheet_add_aoa(
            ws,
            contentList,
            {origin: -1}
        );

        ws['!cols'] = [
            {wpx: 70},
            {wpx: 150},
            {wpx: 100},
            {wpx: 140},
            {wpx: 50},
            {wpx: 70},
            {wpx: 100},
            {wpx: 100},
            {wpx: 100},
            {wpx: 100},
            {wpx: 200},
            {wpx: 100},
            {wpx: 50},
            {wpx: 50},
            {wpx: 100},
            {wpx: 50},
            {wpx: 50},
            {wpx: 100},
        ]

    }


    const wb: any = {Sheets: {data: ws}, SheetNames: ['data']};
    const excelButter = XLSX.write(wb, {bookType: 'xls', type: 'array'});
    const excelFile = new Blob([excelButter], {type: excelFileType});
    FileSaver.saveAs(excelFile, excelFileName + excelFileExtension);
    const base64File = await blobToBase64(excelFile);
    androidTest(base64File);
    return true;
}

async function blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            const base64Data: string = reader.result as string;
            resolve(base64Data);
        };

        reader.onerror = (error) => {
            reject(error);
        };

        reader.readAsDataURL(blob);
    });
}



export const downloadExcelSample = async () => {
    const storageRef = ref(storage, `/form/newWorkForm.xlsx`)
    const url = await getDownloadURL(storageRef)

    return url
    // const excelFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    // const excelFileExtension = '.xlsx';
    // const excelFileName = `블루스카이 엑셀 양식`;
    //
    // const ws = XLSX.utils.aoa_to_sheet([
    //     ['성명', '전화번호', '주소', '요청사항']
    // ]);
    //
    // const wb: any = {Sheets: {data: ws}, SheetNames: ['data']};
    // const excelButter = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
    // const excelFile = new Blob([excelButter], {type: excelFileType});
    // FileSaver.saveAs(excelFile, excelFileName + excelFileExtension);

}
